<template>
    <div class="card-body">
        <div class="card-body-title">Добавить извещение</div>
        <div class="notification-create row flex">
            <div class="col-4">
                <label class="form-label">
                    <div class="t">Заголовок *</div>
                    <input v-model="form.title" placeholder="Заголовок *" required/>
                </label>
            </div>
            <div class="col-4">
                <label class="form-label">
                    <div class="t">Дата отправки *</div>
                    <input v-model="form.date_send" type="date" required/>
                </label>
            </div>
            <div class="col-4">
                <label class="form-label">
                    <div class="t">Отправленный файл *</div>
                    <input class="file_send" type="file" @change="create" accept="video/*, audio/*, image/*, application/msword, application/vnd.ms-excel, text/plain, application/pdf"/>
                    <div class="btn blue inb">Загрузить</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "notificationCreate",
    props: [
        'parent_type',
        'parent_id',
    ],
    data() {
        return {
            form:{
                title:'',
                date_send:false,
                file_send:false,
            },
        }
    },
    methods: {
        create() {
            if (this.form.date_send) {
                this.loading = true;
                this.form.file_send =  this.$el.querySelector('.file_send').files[0];
                let formData = new FormData();
                formData.append('file_send',this.form.file_send);
                formData.append('title',this.form.title);
                formData.append('date_send',this.form.date_send);
                this.$api.post(`claim/${this.$props.parent_id}/notification`,formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.$store.commit('showNotify',{title:'Сохранено',content:'Файл успешно загружен',type:'success'});
                        this.title = '';
                        this.number = '';
                        this.$emit('uploaded');
                    } else {
                        this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                    }
                })
            } else {
                this.$store.commit('showNotify',{title:'Ошибка',content:'Не заполнена дата отправки',type:'danger'});
            }
        },
    },
}
</script>

<style scoped lang="scss">
.notification-create {
    .file_send {display: none;}
}
</style>